/* import ReactDOM from "react-dom"
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
} */

/* import React from "react"
import NavbarProvider from "./src/context/navbar"
export const wrapRootElement = ({ element }) => (
  <NavbarProvider>{element}</NavbarProvider>
) */

import "./src/styles/global.css"
import NavbarProvider from "./src/context/navbar"

export const wrapRootElement = NavbarProvider

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
