// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-checkoutorders-js": () => import("./../../../src/pages/checkoutorders.js" /* webpackChunkName: "component---src-pages-checkoutorders-js" */),
  "component---src-pages-cme-js": () => import("./../../../src/pages/cme.js" /* webpackChunkName: "component---src-pages-cme-js" */),
  "component---src-pages-distributor-js": () => import("./../../../src/pages/distributor.js" /* webpackChunkName: "component---src-pages-distributor-js" */),
  "component---src-pages-google-login-verify-[token]-js": () => import("./../../../src/pages/googleLoginVerify/[token].js" /* webpackChunkName: "component---src-pages-google-login-verify-[token]-js" */),
  "component---src-pages-healthcare-workers-js": () => import("./../../../src/pages/healthcare_workers.js" /* webpackChunkName: "component---src-pages-healthcare-workers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-reset-js": () => import("./../../../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-retail-hospitals-js": () => import("./../../../src/pages/retail_hospitals.js" /* webpackChunkName: "component---src-pages-retail-hospitals-js" */),
  "component---src-pages-setup-js": () => import("./../../../src/pages/setup.js" /* webpackChunkName: "component---src-pages-setup-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms_of_use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-upload-js": () => import("./../../../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */),
  "component---src-pages-verify-email-[token]-js": () => import("./../../../src/pages/verifyEmail/[token].js" /* webpackChunkName: "component---src-pages-verify-email-[token]-js" */),
  "component---src-pages-wholesaler-js": () => import("./../../../src/pages/wholesaler.js" /* webpackChunkName: "component---src-pages-wholesaler-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-distributor-js": () => import("./../../../src/templates/distributor.js" /* webpackChunkName: "component---src-templates-distributor-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-wholesaler-js": () => import("./../../../src/templates/wholesaler.js" /* webpackChunkName: "component---src-templates-wholesaler-js" */)
}

