import React, { useState, useRef, useEffect } from "react"

export const NavbarContext = React.createContext()

const NavbarProvider = props => {
  const isBrowser = typeof window !== "undefined"

  /* const [user] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || null : null
  ) */

  const [user, setUser] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) : null
  )

  const [navbar, toggleNavbar] = useState(false)
  const [thankYouModal, setThankYouModal] = useState(false)

  const [thankYouVerificationModal, setThankYouVerificationModal] = useState(
    false
  )

  const topRef = useRef(null)
  const aboutRef = useRef(null)
  const servicesRef = useRef(null)
  const footerRef = useRef(null)
  const tutorialRef = useRef(null)
  const profileRef = useRef(null)
  const resourcesRef = useRef(null)
  const blogRef = useRef(null)

  const [scrollTo, setScrollTo] = useState(topRef)

  const [isOpenVerifyLoginAlert, setIsOpenVerifyLogin] = useState(
    user ? (user.isVerified ? false : true) : false
  )

  useEffect(() => {
    if (user) {
      setIsOpenVerifyLogin(user.isVerified ? false : true)
    } else {
      setIsOpenVerifyLogin(false)
    }
  }, [user])

  return (
    <NavbarContext.Provider
      value={{
        navbar,
        thankYouModal,
        openThankYouModal: () => {
          setThankYouModal(true)
        },
        closeThankYouModal: () => {
          setThankYouModal(false)
        },
        scrollTo,
        toggle: () => toggleNavbar(!navbar),
        scroll: ref => {
          setScrollTo(ref)
        },
        aboutRef,
        topRef,
        servicesRef,
        footerRef,
        tutorialRef,
        profileRef,
        resourcesRef,
        blogRef,
        isOpenVerifyLoginAlert,
        setIsOpenVerifyLogin,
        setUser,
        thankYouVerificationModal,
        setThankYouVerificationModal,
      }}
    >
      {props.children}
    </NavbarContext.Provider>
  )
}

export default ({ element }) => <NavbarProvider>{element}</NavbarProvider>
